/* eslint-disable @typescript-eslint/no-invalid-void-type */
export interface LoginParams {
  account: string;
  password: string;
}

export interface LoginEntity {
  isFirstTime: boolean;
  token: string;
  openId: string;
  uid: string;
  accessToken: string;
  tenantId: string;
  thirdPartyInfo?: {
    openId: string;
  };
}

export enum VerifyType {
  CODE,
  PHONE,
  EMAIL,
}

export interface RegParams {
  account: string;
  password?: string;
  inviteCode?: string;
  redirectUrl?: string;
  verifyData?: string;
  verifyType: VerifyType;
}

export interface RegEntity {
  authCode: string;
  loginTicket: string;
}

export type LogoutParams = undefined;

export type LogoutEntity = void;

export interface DeprecateParams {
  account: string;
}

export type DeprecateEntity = void;

export interface ActivateParams {
  account: string;
}

export type ActivateEntity = void;

export interface ChangepwdParams {
  newpwd: string;
  oldpwd: string;
  redirectUrl?: string;
}

export type ChangepwdEntity = void;

export interface Question {
  answerIs: string;
  template: number;
}

export interface SettingQuestionParams {
  questions: Question[];
}

export type SettingQuestionEntity = void;

export enum RetrieveType {
  PHONE = 1,
  EMAIL,
  QUESTION,
}

export interface RetrieveParams {
  account: string;
  redirectUrl?: string;
  type: RetrieveType;
  verifyData?: string;
}

export interface RetrieveEntity {
  ticket: string;
}

export interface RetrieveByQuestionParams {
  account: string;
  questions: Question[];
}

export interface RetrieveByQuestionEntity {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface ResetpwdParams {
  newpwd: string;
}

export type ResetpwdEntity = void;

export interface AuthCodeParams {
  // 如果没有，从header中获取
  appId?: string;
  authType: number;
  contact: string;
}

export interface AuthCodeEntity {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface CodeChangePwdParams {
  authCode: string;
  contact: string;
  newpwd: string;
}

export type CodeChangePwdEntity = void;

export interface CodeLoginParams {
  authCode: string;
  contact: string;
}

export interface CodeLoginEntity {
  isFirstTime: boolean;
  ticket: string;
  uid: string;
}

export interface TicketLoginParams {
  account?: string;
  source: string;
  ticket: string;
}

export type TicketLoginEntity = LoginEntity;

export interface TicketResetPwdParams {
  param?: string;
  ticket?: string;
  account?: string;
  newpwd?: string;
}

export type TicketResetPwdEntity = void;

export interface OrganizationEntity {
  address: string;
  company: string;
  phone: string;
  title: string;
}

export interface UserBasicInfoEntity {
  avatar: string;
  avatarBig: string;
  avatarMiddle: string;
  avatarThumb: string;
  email: string;
  enName: string;
  gender: string;
  idCard: string;
  name: string;
  organization?: OrganizationEntity;
  phone: string;
  openId?: string;
  loginInfo?: Record<string, any>;
}

/* 登录渠道类型 */
export enum LoginChannel {
  Default = 'default', // 默认渠道
  Zixel = 'jumeaux', // 子虔内部帐号系统
  DingDing = 'dingtalk',
  FeiShu = 'feishu',
  HuaWei = 'huawei',
}

export interface UserInfoEntity {
  /**
   * 登录账号名
   */
  accountName: string;
  /**
   * 登录账号来源
   */
  accountSource: LoginChannel;
  /**
   * 登录应用
   */
  appId: number;
  /**
   * 用户头像
   */
  avatar: string;
  phone?: string;
  /**
   * 用户头像_大
   */
  avatarBig?: string;
  /**
   * 用户头像_中
   */
  avatarMiddle?: string;
  /**
   * 用户头像_小
   */
  avatarThumb?: string;
  /**
   * 用户英文昵称
   */
  enName?: string;
  /**
   * 用户性别，1-male, 2-female
   */
  gender?: number;
  /**
   * 用户昵称
   */
  name: string;
  /**
   * 用户应用身份openId
   */
  openId: string;
  /**
   * 公司信息
   */
  organization?: { [key: string]: any };
  /**
   * 登录平台
   */
  platform: number;
  /**
   * 登录租户
   */
  tenantId: string;
  unionId?: string;
}

export type UserUpdateParams = Partial<{
  basic: Partial<{
    enName: string;
    name: string;
    organization: Partial<OrganizationEntity>;
  }>;
  // IDK
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specific?: Record<string, any>;
}>;

export type TenantUpdateParams = Partial<{
  enName: string;
  name: string;
  organization: Partial<OrganizationEntity>;
}>;

export type UserUpdateEntity = void;

export interface ResetPwdEmit {
  account: string;
  password: string;
}

export type AvatarUpdateParams = Partial<{
  avatar: {
    fileName: string;
    size: number;
  };
  avatarBig: {
    fileName: string;
    size: number;
  };
  avatarMiddle: {
    fileName: string;
    size: number;
  };
  avatarThumb: {
    fileName: string;
    size: number;
  };
}>;

export type AvatarUpdateEntity = Partial<{
  avatar: string;
  avatarBig: string;
  avatarMiddle: string;
  avatarThumb: string;
}>;

export interface ContactBindParams {
  bind: string;
  redirectUrl?: string;
  type: number;
  verifyData?: string;
}

export interface ContactChangeParams {
  authCode: string;
  contact: string;
}

export type ContactBindEntity = Record<string, unknown>;

export interface InviteInfoParams {
  inviteCode: string;
}

export enum IdentifierType {
  phone = 1,
  email,
}

/**
 * 支持多端同时登录，Platform_Id设置成后端可支持的随机数
 */
const random = Math.floor(Math.random() * 2147483646) + 1;
export enum PlatForms  {    
  PC = random,
  Mobile = random + 1,
}

export enum LoginStates {
  login = 'login',
  register = 'register',
  forget = 'forget',
}

export enum Env {
  dev = 'dev',
  qa = 'qa',
  main = 'main',
}

export enum AccountTypes {
  email = 'email',
  phone = 'phone',
  all = 'all',
}

export enum LoginPages {
  code = 'codeLogin',
  pwd = 'pwdLogin',
}

export interface InviteInfoEntity {
  identifier: string;
  type: number;
}

export interface ChangeTenatParams {
  /**
   * 新登录的租户
   */
  newTenantId: string;
}

/**
 * TokenInfo
 */
export interface ChangeTenatResponse {
  /**
   * 用户访问凭证
   */
  accessToken?: string;
  /**
   * 用户应用身份openId
   */
  openId?: string;
  /**
   * 凭证状态.1-FullToken，对应完整用户信息, 2-PartialToken，对应缺少租户的部分用户信息
   */
  status?: number;
  /**
   * 用户应用身份unionId
   */
  unionId?: string;
}
