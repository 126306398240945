/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, Store, useStore } from 'vuex';
import modules from './modules';
import { RootStateTypes } from '@/store/interface/index';

type StoreNamespace = keyof typeof modules;

/**
 * 这个hook提供namespace级别的store
 * 问题在于我并未使用infer令commit和dispatch可以被类型推断
 * 他们依然是无类型标记的，无法进行推断
 * @param namespace - 命名空间
 * @returns Store<T>
 */
export function useStoreHook<T = RootStateTypes>(
  namespace?: StoreNamespace,
): Store<T> {
  const store = useStore();
  if (!namespace) {
    return store;
  }
  const module: any = {};

  // just one level
  Object.defineProperties(module, {
    state: {
      get() {
        return store.state[namespace];
      },
    },
    getters: {
      get() {
        const getters: Record<string, any> = {};
        const list = Object.keys(store.getters);
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          if (element.startsWith(namespace)) {
            getters[element.replace(`${namespace}/`, '')] =
              store.getters[element];
          }
        }
        return getters;
      },
    },
    replaceState: {
      get() {
        return function (state: any) {
          store.replaceState({
            ...store.state,
            [namespace]: state,
          });
        };
      },
    },
    dispatch: {
      get() {
        return function (...args: any[]) {
          const args1 = args[0];
          if (typeof args1 === 'string') {
            return store.dispatch(`${namespace}/${args1}`, ...args.slice(1));
          }
          if (typeof args1 === 'object') {
            return store.dispatch({
              ...args1,
              type: `${namespace}/${args1.type}`,
            });
          }
          throw new Error('invalid dispatch');
        };
      },
    },
    commit: {
      get() {
        return function (...args: any[]) {
          const args1 = args[0];
          if (typeof args1 === 'string') {
            return store.commit(`${namespace}/${args1}`, ...args.slice(1));
          }
          if (typeof args1 === 'object') {
            return store.commit({
              ...args1,
              type: `${namespace}/${args1.type}`,
            });
          }
          throw new Error('invalid dispatch');
        };
      },
    },
    subscribe: {
      get() {
        return function (fn: any, options?: any) {
          return store.subscribe((mutation: any, state: any) => {
            if (mutation.type.startsWith(namespace)) {
              fn(
                {
                  ...mutation,
                  type: mutation.type.replace(`${namespace}/`, ''),
                },
                state[namespace],
              );
            }
          }, options);
        };
      },
    },
    subscribeAction: {
      get() {
        return function (fn: any, options?: any) {
          return store.subscribeAction((action: any, state: any) => {
            if (action.type.startsWith(namespace)) {
              fn(
                {
                  ...action,
                  type: action.type.replace(`${namespace}/`, ''),
                },
                state[namespace],
              );
            }
          }, options);
        };
      },
    },
    watch: {
      get() {
        return function (getter: any, cb: any, options?: any) {
          return store.watch(
            (state: any, getters: any) => {
              return getter(state[namespace], getters);
            },
            cb,
            options,
          );
        };
      },
    },
    registerModule: {
      get() {
        return function (path: any, moduleName: any, options?: any) {
          store.registerModule(`${namespace}/${path}`, moduleName, options);
        };
      },
    },
    unregisterModule: {
      get() {
        return function (path: any) {
          store.unregisterModule(`${namespace}/${path}`);
        };
      },
    },
    hasModule: {
      get() {
        return function (path: any) {
          return store.hasModule(`${namespace}/${path}`);
        };
      },
    },
    hotUpdate: {
      get() {
        // no need to implemented
      },
    },
  });

  return module;
}

export default createStore<RootStateTypes>({
  // state: {},
  // mutations: {},
  // actions: {},
  modules,
});
