const theme = {
  blue: '#165dff',
  borderColor: '#dcdfe6',
  dangerColor: '#f56c6c',
  tabbarItemTextColor: '#606266',
  tabbarItemActiveColor: '#165dff',
  dividerBorderColor: '#e4e7ed',
  dividerMargin: 0,
  disabledOpacity: 0.4,
  primaryColor: '#165dff',
  textColor: '#303133',
  fieldInputTextColor: '#303133',
  fieldErrorMessageColor: '#f56c6c',
  fieldInputErrorTextColor: '#f56c6c',
  fieldPlaceholderTextColor: '#a8abb2',
  buttonPrimaryBorderColor: '#165dff',
  buttonPrimaryBackgroundColor: '#165dff',
  buttonDisabledOpacity: 0.4,
  buttonDefaultColor: '#303133',
  buttonDefaultBorderColor: '#dcdfe6',
  dropdownMenuBackgroundColor: '#f7f9fa',
  dropdownMenuTitleTextColor: '#606266',
  passwordInputCursorColor: '#165dff',
  passwordInputTextColor: '#303133',
  cellTextColor: '#303133',
  cellBorderColor: '#dcdfe6',
  cellRequriedColor: '#f56c6c',
  cellLabelColor: '#909399',
  cellRightIconColor: '#a8abb2',
  switchBackgroundColor: '#dcdfe6',
  switchOnBackgroundColor: '#165dff',
  switchDisabledOpacity: 0.4,
};

export default function useTheme() {
  return {
    theme,
  };
}
