/* eslint-disable no-param-reassign */
import { Local } from '@/utils/storage';
import { ErrorEntity } from './interface';
import { appId, PlatForms } from '@/service/constants';
import {
  create401Error,
  createServiceError,
  errorHandler,
  goToLogin,
  responseHandler,
  serviceCreator,
  showMessage,
} from '@/service/utils';
import { LocalKeys } from '@/utils/constants';

export enum BussnessCode {
  NOT_AUTH = 2110001,
  NOT_EXIST = 2110002,
  NULL_POINT = 2110003,
  INDEX_OUT_OF = 2110004,
  CLASS_NOT_FOUND = 2110005,
  SQL = 2110006,
  IOE = 2110007,
  EXC = 2110008,
  TYPE_ERO = 2110009,
  ALREADY_EXIST = 2110010,
  // 组织结构token验证失效
  COL_NOT_AUTH = 2310001,
  // 组织结构无权限
  NO_APP_RIGHT = 2310014,
  // 组织结构无权限 新
  NO_APP_RIGHT_NEW = 91201,
}

// 截断器雏形
const axiosConfig = {
  timeout: 50000,
  // 本地开发需要接set-cookie，需要配置一下
  baseURL: process.env.VUE_APP_ZPM_URL,
  withCredentials: true,
};

export const service = serviceCreator(axiosConfig, (inner, userConfig) => {
  inner.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {};
    }
    if (Local.get(LocalKeys.FEISHU_APP_ID)) {
      config.headers.feishuid = Local.get(LocalKeys.FEISHU_APP_ID);
      config.headers['Zixel-Feishu-Id'] = Local.get(LocalKeys.FEISHU_APP_ID);
    }
    config.headers['Zixel-Platform-Id'] = PlatForms.Mobile;
    config.headers['Zixel-Application-Id'] = appId;
    if (!Local.get(LocalKeys.ACCESS_TOKEN)) {
      goToLogin();
    }
    if (Local.get(LocalKeys.OPEN_ID)) {
      config.headers['Zixel-Open-Id'] = Local.get(LocalKeys.OPEN_ID);
    }
    if (Local.get(LocalKeys.ACCESS_TOKEN)) {
      config.headers['Zixel-Auth-Token'] = Local.get(LocalKeys.ACCESS_TOKEN);
    }
    if (Local.get(LocalKeys.TENANT_ID)) {
      config.headers['zixel-organization-id'] = Local.get(LocalKeys.TENANT_ID);
    }
    return config;
  });

  // 添加响应拦截器
  inner.interceptors.response.use(
    (response) => {
      const res = response.data as ErrorEntity;
      if (res && typeof res === 'object' && res.error) {
        // 修正错误码
        if (
          res.error.code === BussnessCode.NOT_AUTH ||
          res.error.code === BussnessCode.COL_NOT_AUTH
        ) {
          showMessage(userConfig, '未登录或登录失效，请重新登录', 'warning');
          goToLogin();
          response.status = 401;
          return create401Error(response);
        }
        responseHandler(res, userConfig);
        return createServiceError(res, response);
      }
      return response;
    },
    (error) => errorHandler(error, userConfig),
  );
});
