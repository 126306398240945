import { ProjectItem, ProjectTypes } from '@/service/modules/zpm/interface';

export const projectItemCreator = (): ProjectItem => {
  return {
    authFlag: 0,
    authPoint: [],
    children: [],
    companyId: '',
    isExternalShare: 0,
    isInside: 0,
    path: '',
    dr: 0,
    id: '',
    metaId: '',
    version: 0,
    isShare: 0,
    key: '',
    name: '',
    nextId: 0,
    nodeId: '',
    jobId: '',
    rootId: '',
    parentId: '',
    parentName: '',
    preId: 0,
    remark: '',
    shareType: '',
    size: 0,
    status: 0,
    type: ProjectTypes.project,
    updateTime: 0,
    updatedBy: '',
    placeOnFileId: '',
    owner: '',
    versionTag: null,
    versionStr: '',
  };
};
