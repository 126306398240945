import { info } from '@/service/modules/user';
import { UserInfoEntity, LoginChannel } from '@/service/modules/user/interface';
import { LocalKeys } from '@/utils/constants';
import { Local } from '@/utils/storage';
import { ref } from 'vue';

function userCreator(): UserInfoEntity {
  return {
    openId: '',
    unionId: '',
    name: '游客',
    phone: '',
    avatar: '',
    tenantId: '',
    appId: 0,
    platform: 2,
    accountSource: LoginChannel.Zixel,
    accountName: '',
  };
}

const user = ref(userCreator());
const inited = ref(false);

export default function useUser() {
  const init = async () => {
    try {
      const { data: userInfo } = await info(Local.get(LocalKeys.OPEN_ID));
      user.value = userInfo;
      inited.value = true;
    } catch (e) {
      // ignore
    }
  };

  return {
    init,
    user,
    inited,
  };
}
