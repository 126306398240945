/* eslint-disable no-param-reassign */
const initState = {
  userInfo: null,
  codeCount: 3,
};
const mutations = {
  setUserInfo: (state, val) => {
    state.userInfo = val;
  },
  setCodeCount: (state, val) => {
    state.codeCount = val;
  },
};

export default {
  namespaced: true,
  state: initState,
  mutations,
};
