import 'amfe-flexible';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant, { Toast } from 'vant';
import 'vant/lib/index.css';
import IconFont from './components/IconFont.vue';
import '@/components/dropdown/index.scss';
import VTouch from '@any-touch/vue3';
// import focusDirective from './utils/touchMove';
import { initVConsole } from '@zixel/vue-cli-plugin-builder/src/debug';

Toast.allowMultiple();

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(Vant)
  .use(Toast)
  .use(VTouch)
  .component('icon-font', IconFont)
  .mount('#app');

// initVConsole();
