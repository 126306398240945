/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserConfig } from '@/service/utils';
import { Local } from '@/utils/storage';
import { AxiosPromise, AxiosRequestHeaders } from 'axios';
import { service } from './config';
import {
  LoginParams,
  LoginEntity,
  RegParams,
  RegEntity,
  LogoutEntity,
  DeprecateParams,
  DeprecateEntity,
  ActivateParams,
  ActivateEntity,
  ChangepwdParams,
  ChangepwdEntity,
  SettingQuestionParams,
  SettingQuestionEntity,
  RetrieveParams,
  RetrieveEntity,
  RetrieveByQuestionParams,
  RetrieveByQuestionEntity,
  ResetpwdParams,
  ResetpwdEntity,
  AuthCodeParams,
  AuthCodeEntity,
  CodeChangePwdParams,
  CodeChangePwdEntity,
  CodeLoginParams,
  CodeLoginEntity,
  TicketLoginParams,
  TicketLoginEntity,
  TicketResetPwdParams,
  TicketResetPwdEntity,
  UserInfoEntity,
  UserBasicInfoEntity,
  TenantUpdateParams,
  UserUpdateParams,
  UserUpdateEntity,
  AvatarUpdateParams,
  AvatarUpdateEntity,
  ContactBindParams,
  ContactBindEntity,
  ContactChangeParams,
  InviteInfoParams,
  InviteInfoEntity,
  ChangeTenatParams,
  ChangeTenatResponse,
} from './interface';
import { LocalKeys } from '@/utils/constants';
/**
 * 用户密码登录
 * @param params
 * @returns
 */
export function login(
  params: LoginParams,
  userConfig?: UserConfig,
): AxiosPromise<LoginEntity> {
  return service(
    {
      url: `/account/login`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function ticketLogin(
  params: TicketLoginParams,
  headers?: AxiosRequestHeaders,
  userConfig?: UserConfig,
): AxiosPromise<TicketLoginEntity> {
  return service(
    {
      url: `/account/ticketLogin`,
      method: 'POST',
      data: params,
      headers,
    },
    userConfig,
  );
}

/**
 * 注册
 * @param params
 * @returns
 */
export function reg(
  params: RegParams,
  userConfig?: UserConfig,
): AxiosPromise<RegEntity> {
  return service(
    {
      url: `/account/reg`,
      method: 'POST',
      data: params,
      params: params.inviteCode
        ? {
            inviteCode: params.inviteCode,
          }
        : undefined,
    },
    userConfig,
  );
}

/**
 * 登出
 * @param params
 * @returns
 */
export function logout(userConfig?: UserConfig): AxiosPromise<LogoutEntity> {
  return service(
    {
      url: '/account/logout',
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 账号注销
 * @param params
 * @returns
 */
export function deprecate(
  params: DeprecateParams,
  userConfig?: UserConfig,
): AxiosPromise<DeprecateEntity> {
  return service(
    {
      url: `/account/${params.account}/deprecate`,
      method: 'DELETE',
    },
    userConfig,
  );
}

/**
 * 搜东激活被注销或禁用账号
 * @param params
 * @returns
 */
export function activate(
  params: ActivateParams,
  userConfig?: UserConfig,
): AxiosPromise<ActivateEntity> {
  return service(
    {
      url: `/account/${params.account}/activate`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 修改密码
 * @param params
 * @returns
 */
export function changepwd(
  params: ChangepwdParams,
  userConfig?: UserConfig,
): AxiosPromise<ChangepwdEntity> {
  return service(
    {
      url: `/account/changepwd`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 验证码用户修改密码
 * @param params
 * @returns
 */
export function codeChangePwd(
  params: CodeChangePwdParams,
  userConfig?: UserConfig,
): AxiosPromise<CodeChangePwdEntity> {
  return service(
    {
      url: `/account/codeChangePwd`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 验证码的登录
 * @param params
 * @returns
 */
export function codeLogin(
  params: CodeLoginParams,
  userConfig?: UserConfig,
): AxiosPromise<CodeLoginEntity> {
  return service(
    {
      url: `/account/codeLogin`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 设置密保问题
 * @param params
 * @returns
 */
export function settingQuestion(
  params: SettingQuestionParams,
  userConfig?: UserConfig,
): AxiosPromise<SettingQuestionEntity> {
  return service(
    {
      url: `/account/settingQuestion`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 密码找回
 * @param params
 * @returns
 */
export function retrieve(
  params: RetrieveParams,
  userConfig?: UserConfig,
): AxiosPromise<RetrieveEntity> {
  return service(
    {
      url: `/account/${params.account}/retrieve`,
      method: 'GET',
      params: {
        ...params,
        account: undefined,
      },
    },
    userConfig,
  );
}

/**
 * 发送登录及修改密码验证码
 * @param params
 * @returns
 */
export function authcode(
  params: AuthCodeParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthCodeEntity> {
  return service(
    {
      url: `/account/authcode`,
      method: 'GET',
      params,
    },
    userConfig,
  );
}

/**
 * 通过安全问题找回密码
 * @param params
 * @returns
 */
export function retrieveByQuestion(
  params: RetrieveByQuestionParams,
  userConfig?: UserConfig,
): AxiosPromise<RetrieveByQuestionEntity> {
  return service(
    {
      url: `/account/retrieveByQuestion/${params.account}`,
      method: 'POST',
      data: {
        questions: params.questions,
      },
    },
    userConfig,
  );
}

/**
 * 重置密码
 * @param params
 * @returns
 */
export function resetpwd(
  params: ResetpwdParams,
  userConfig?: UserConfig,
): AxiosPromise<ResetpwdEntity> {
  return service(
    {
      url: `/account/resetpwd`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function ticketResetPwd(
  params: TicketResetPwdParams,
  userConfig?: UserConfig,
): AxiosPromise<TicketResetPwdEntity> {
  return service(
    {
      url: '/account/ticketResetPwd',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 获取用户全量信息 + 登录态
 * @returns
 */
export function info(userConfig?: UserConfig): AxiosPromise<UserInfoEntity> {
  return service(
    {
      url: `/user/v2/tanent-detail`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 获取用户信息
 * @returns
 */
export function detail(
  userConfig?: UserConfig,
): AxiosPromise<UserBasicInfoEntity> {
  return service(
    {
      url: `/user/detail`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 用户应用信息更新
 * @param params
 * @returns
 */
export function update(
  params: UserUpdateParams,
  userConfig?: UserConfig,
): AxiosPromise<UserUpdateEntity> {
  return service(
    {
      url: `/user/app/update`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 租户信息更新
 * @param params
 * @returns
 */
export function TenantUpdate(
  params: TenantUpdateParams,
): AxiosPromise<UserUpdateEntity> {
  return service({
    url: `/user/v2/info/tenant`,
    method: 'PATCH',
    data: params,
  });
}

/**
 * 用户头像信息更新
 * @param params
 * @returns
 */
export function AvatarUpdate(
  params: AvatarUpdateParams,
  userConfig?: UserConfig,
): AxiosPromise<AvatarUpdateEntity> {
  return service(
    {
      url: `/user/app/avatar/update`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 改用户联系方式
 * @param params
 * @returns
 */
export function ContactBind(
  params: ContactBindParams,
  userConfig?: UserConfig,
): AxiosPromise<ContactBindEntity> {
  return service(
    {
      url: `/user/contact/bind`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 修改用户头像
 * @param params
 * @returns
 */
export function avatarUpdate(params: any): AxiosPromise<ContactBindEntity> {
  return service({
    url: `user/basic/avatar/update`,
    method: 'POST',
    data: params,
  });
}

/**
 * 改用户账号
 * @param params
 * @returns
 */
export function AccountChange(
  params: ContactChangeParams,
  userConfig?: UserConfig,
): AxiosPromise<ContactBindEntity> {
  return service(
    {
      url: `/account/contact/change`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 查询用户拥有的权限集合
 */
export function listPrivileges(
  path = '/API-RESOURCE-DIRECTOR',
): AxiosPromise<string[]> {
  return service({
    url: `/privilege/authentication/privilege/get`,
    method: 'GET',
    params: {
      appId: 'collaborate',
      path,
      uid: Local.get(LocalKeys.OPEN_ID),
    },
  });
}

/**
 * 查询用户拥有的权限集合
 */
export function accountInfo(userConfig?: UserConfig): AxiosPromise<string[]> {
  return service(
    {
      url: `/account/info`,
      method: 'GET',
    },
    userConfig,
  );
}

export function inviteInfo(
  params: InviteInfoParams,
  userConfig?: UserConfig,
): AxiosPromise<InviteInfoEntity> {
  return service(
    {
      url: `/account/invite-info`,
      method: 'GET',
      params,
    },
    userConfig,
  );
}

export function changeTenant(
  params: ChangeTenatParams,
  userConfig?: UserConfig,
): AxiosPromise<ChangeTenatResponse> {
  return service(
    {
      url: `/user/v2/change-tenant`,
      method: 'POST',
      params,
    },
    userConfig,
  );
}
