/* eslint-disable no-param-reassign */
import { Local } from '@/utils/storage';
import { ErrorEntity } from '../zpm/interface';
import { appId, PlatForms } from '@/service/constants';
import {
  createServiceError,
  errorHandler,
  goToLogin,
  responseHandler,
  serviceCreator,
} from '@/service/utils';
import { LocalKeys } from '@/utils/constants';
// 截断器雏形

// 这些服务是不需要设置 token的
const whiteList = [
  '/account/login',
  '/account/ticketLogin',
  '/account/reg',
  '/account/codeChangePwd',
  '/account/codeLogin',
  // 注意这个密码找回可能有问题
  '/retrieve',
  '/account/authcode',
  '/account/retrieveByQuestion',
  '/account/ticketResetPwd',
  '/account/invite-info',
];

function isWhiteList(url: string) {
  for (let index = 0; index < whiteList.length; index++) {
    const element = whiteList[index];
    if (url.includes(element)) {
      return true;
    }
  }
  return false;
}

const axiosConfig = {
  timeout: 50000,
  // 本地开发需要接set-cookie，需要配置一下
  baseURL: process.env.VUE_APP_USER_URL,
  withCredentials: true,
};

export const service = serviceCreator(axiosConfig, (inner, userConfig) => {
  inner.interceptors.request.use((configRef) => {
    if (!configRef.headers) {
      configRef.headers = {};
    }
    if (Local.get(LocalKeys.FEISHU_APP_ID)) {
      configRef.headers.feishuid = Local.get(LocalKeys.FEISHU_APP_ID);
      configRef.headers['Zixel-Feishu-Id'] = Local.get(LocalKeys.FEISHU_APP_ID);
    }
    configRef.headers['Zixel-Platform-Id'] = PlatForms.Mobile;
    configRef.headers['Zixel-Application-Id'] = appId;
    if (isWhiteList(configRef.url as string)) {
      // 还需要配置requestId,和灰度发布功能RequestSelector
      // 详见 [文档](https://zixel-technologies.feishu.cn/docs/doccnwPrgw22O0By6OM7PAtyIvd)
      return configRef;
    }
    if (!Local.get(LocalKeys.ACCESS_TOKEN)) {
      goToLogin();
    }
    if (Local.get(LocalKeys.OPEN_ID)) {
      configRef.headers['Zixel-Open-Id'] = Local.get(LocalKeys.OPEN_ID);
    }
    if (Local.get(LocalKeys.ACCESS_TOKEN)) {
      configRef.headers['Zixel-Auth-Token'] = Local.get(LocalKeys.ACCESS_TOKEN);
    }
    if (Local.get(LocalKeys.TENANT_ID)) {
      configRef.headers['zixel-organization-id'] = Local.get(
        LocalKeys.TENANT_ID,
      );
    }
    return configRef;
  });

  // 添加响应拦截器
  inner.interceptors.response.use(
    (response) => {
      const res = response.data as ErrorEntity;
      if (res && typeof res === 'object' && res.error) {
        responseHandler(res, userConfig);
        return createServiceError(res, response);
      }
      return response;
    },
    (error) => errorHandler(error, userConfig),
  );
});
