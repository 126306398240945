import { getCrossAppValues } from '@zixel/zaf';

export enum AuthCode {
  // 企业级权限设置
  ENTERPRISE_LEVEL_PERMISSION_SETTING = 10001,
  // 对外分享设置
  SHARE_PUBLIC = 10002,
  // 开启链接分享
  LINK_SHARE_OPEN = 10003,
  // 链接分享设置
  LINK_SHARE_SETTING = 10004,
  // 链接分享关
  // LINK_SHARE_OFF = 10005,
  // 分享
  SHARE = 10006,
  // 管理分支
  BRANCH_MANAGE = 10007,
  // 设置权限
  SETTING = 10008,
  // 上传文件
  FILE_UPLOAD = 10009,
  // 更新文件
  FILE_UPDATE = 10010,
  // 删除文件
  FILE_DELETE = 10011,
  // 创建文件夹
  FOLDER_CREATE = 10012,
  // 更新文件夹
  FOLDER_UPDATE = 10013,
  // 删除文件夹
  FOLDER_DELETE = 10014,
  // 创建分支
  BRANCH_CREATE = 10015,
  // 查看
  VIEW = 10016,
  // 管理协作者
  COLLABORATOR_MANAGE = 10017,
  // 下载
  DOWNLOAD = 10018,
  // 批注
  ANNOTATE = 10019,
  // 项目修改
  PROJECT_UPDATE = 10020,
  // 项目删除
  PROJECT_DELETE = 10021,
  // 添加协作者
  COLLABORATOR_ADD = 10022,
  // 新建存档
  ARCHIVE_CREATE = 10023,
  // 文件历史版本
  FILE_HISTORY = 10024,
  // 编辑存档
  ARCHIVE_UPDATE = 10025,
  // 是否可设置所有者
  OWN_SETTING = 10026,
}

export enum AuthGroup {
  INT = '互联网',
  COM = '公司',
  OWN = '拥有者',
  MAN = '管理',
  EDI = '编辑',
  VIE = '查看',
}

export const HistoryPoints = [
  AuthCode.VIEW,
  AuthCode.DOWNLOAD,
  AuthCode.SHARE,
  AuthCode.FILE_HISTORY,
  AuthCode.ANNOTATE,
];

/**
 * 邮箱地址
 */
export enum MailUrlEnum {
  /** web环境 */
  WEB = 'mailto:support@zixel.cn',

  /** 飞书环境 */
  FEISHU = 'https://applink.feishu.cn/client/helpdesk/open?id=7155355006164697090&extra=%7B%22channel%22%3A1%2C%22created_at%22%3A1666687498%7D',
}

export enum LocalKeys {
  OPEN_ID = '_zixel_open_id',
  TOKEN = '_zixel_token',
  ACCESS_TOKEN = '_zixel_access_token',
  TENANT_ID = '_zixel_organization_id',
  FEISHU_APP_ID = 'feishuId',
  FEISHU_OPEN_ID = 'feishuOpenId',
}

const crossAppValues = getCrossAppValues();

export const AppId =
  crossAppValues && crossAppValues.appId ? crossAppValues.appId : 'collaborate';
