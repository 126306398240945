import { Local } from './../utils/storage';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import useUser from '@/hooks/useUser';
import { LocalKeys } from '@/utils/constants';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: import('../views/home/index.vue'),
    redirect: '/project',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/loginHome.vue'),
  },
  {
    path: '/login2',
    name: 'login2',
    component: () => import('../views/login/loginHome2.vue'),
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project/index.vue'),
    meta: {
      toKeep: ['fileList'],
    },
  },
  {
    path: '/fileList/:companyId/:id',
    name: 'fileList',
    component: () => import('../views/list/index.vue'),
    meta: {
      toKeep: ['cadHome', 'director', 'pdf'],
    },
  },
  {
    path: '/nocompany',
    name: 'nocompany',
    component: () => import('../views/defaults/NoCompany.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/profileIndex.vue'),
  },
  {
    path: '/cadHome',
    name: 'cadHome',
    component: () => import('../views/mobileCad/mobileHome.vue'),
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/pdf/index.vue'),
    props({ query }) {
      return { fid: query.id };
    },
  },
  // fallback
  {
    path: '/:w+',
    name: '*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 登录态判断
router.beforeEach((to, from, next) => {
  if (
    to.path === '/login2' ||
    to.path === '/login' ||
    to.path === '/forgetPwd' ||
    to.path === '/reg' ||
    to.path === '/account'
  ) {
    next();
  } else {
    const isLogin = !!Local.get(LocalKeys.ACCESS_TOKEN);
    if (isLogin) {
      const u = useUser();
      if (!u.inited.value) {
        u.init();
      }
      const keepList = (from.meta?.toKeep as string[]) || [];
      if (keepList.includes(to.name as string)) {
        store.commit('router/addKeepAlive', from.name);
        setTimeout(() => {
          next();
        });
        return;
      }
      next();
      setTimeout(() => {
        store.commit('router/removeKeepAlive');
      });
    } else {
      next('/login');
    }
  }
});

export default router;
