import { UserConfig } from '@/service/utils';
import { AxiosPromise } from 'axios';
import qs from 'qs';
import { service } from './config';
import {
  AddFileEntity,
  AddFileParams,
  AddProjectEntity,
  AddProjectParams,
  AuthAddParams,
  AuthAddEntity,
  AuthorityCreateEntity,
  AuthorityCreateParams,
  AuthorityGetEntity,
  AuthorityGetParams,
  AuthorityRemoveEntity,
  AuthorityRemoveParams,
  AuthorityUpdateEntity,
  AuthorityUpdateParams,
  CreateFolderEntity,
  CreateFolderParams,
  DeleteFileEntity,
  DeleteFileParams,
  DeleteFolderEntity,
  DeleteFolderParams,
  DeleteProjectEntity,
  DeleteProjectParams,
  DownloadFileEntity,
  DownloadFileParams,
  QueryFilesEntity,
  QueryFilesParams,
  QueryProjectsEntity,
  UpdateFileEntity,
  UpdateFileParams,
  UpdateFolderEntity,
  UpdateFolderParams,
  UpdateProjectEntity,
  UpdateProjectParams,
  AuthGetEntity,
  AuthGetParams,
  AuthRemoveEntity,
  AuthRemoveParams,
  AuthUpdateEntity,
  AuthUpdateParams,
  QueryNodeByIdParams,
  QueryNodeByIdEntity,
  BatchDeleteParams,
  BatchDeleteEntity,
  BatchMoveParams,
  BatchMoveEntity,
  GetBatchDownloadUrlParams,
  GetBatchDownloadUrlEntity,
  QueryFolderParams,
  QueryFolderEntity,
  ProjectItem,
  QueryObsUpUrlEntity,
  QueryObsUpUrlParams,
  GetNodeAuthPointParams,
  GetNodeAuthPointEntity,
  SetAuthPointEntity,
  SetAuthPointParams,
  CreatePlaceOnFileParams,
  CreatePlaceOnFileEntity,
  GetPlaceOnFilesParams,
  GetPlaceOnFilesEntity,
  UpdatePlaceOnFile,
  UpdatePlaceOnFileEntity,
  QueryParams,
  SetShareAuthGroupParams,
  SetShareAuthGroupEntity,
  SetShareAuthEntity,
  SetShareAuthParams,
  GetShareAuthGroupParams,
  GetShareAuthGroupEntity,
  CreateCompanyParams,
  CreateCompanyEntity,
  UpdateCompanyParams,
  UpdateCompanyEntity,
  GetUserCompanyListParams,
  GetUserCompanyListEntity,
  QueryCompanyUsersParams,
  UserInfoType,
  QueryCompanyUsersEntity,
  AddCompanyUserParams,
  AddCompanyUserEntity,
  DeleteCompanyUserParams,
  DeleteCompanyUserEntity,
  AddCompanyOutsideUserParams,
  AddCompanyOutsideUserEntity,
  SendBotParams,
  SendBotEntity,
  TransferProgressEntity,
  GetShareLinkDetailParams,
  GetShareLinkDetailEntity,
  GetShareProjectAuthParams,
  GetShareProjectAuthEntity,
  QueryShareListParams,
  QueryShareListEntity,
  PlaceOnFile,
  LatestPlaceOnFilesParams,
  ChangeTypeStatisticsParams,
  ChangeTypeStatisticsEntity,
  DetailAndRootParams,
  DetailAndRootEntity,
  SetCompanyUserRoleParams,
  InviteUserParams,
  SwitchCompanyParams,
  UpdateCompanyUserInfoParams,
  UploadFolderFileParams,
  BatchDeleteUserParams,
  BatchDeleteProjectParams,
  FileProcessParams,
  FileProcessEntity,
  UpdateSingleFileParams,
  BatchAuthAddParams,
} from './interface';
import { globals } from '@/utils/global';

/**
 * 添加多个不同权限的协作者
 * @param params
 * @param userConfig
 * @returns
 */
export function addSingleUserAuth(
  id: string,
  params: BatchAuthAddParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthAddEntity> {
  return service(
    {
      url: `/zetareview/auth/addSingleUserAuth?id=${id}`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 添加协作者
 * @param params
 * @returns
 */
export function addUserAuth(
  params: AuthAddParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthAddEntity> {
  return service(
    {
      url: `/zetareview/auth/addUserAuth`,
      method: 'post',
      data: params,
    },
    userConfig,
  );
}
/**
 * 添加协作者
 * @param params
 * @returns
 */
export function addNotifyAuth(
  params: AuthAddParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthAddEntity> {
  return service(
    {
      url: `/zetareview/auth/addUserAuth?ignoreHighLevelAuth=${params.ignoreHighLevelAuth}`,
      method: 'post',
      data: params,
    },
    userConfig,
  );
}

/**
 * 查询协作者列表
 * @param params
 * @returns
 */
export function getAuthUsers(
  params: AuthGetParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthGetEntity> {
  return service(
    {
      url: `/zetareview/auth/getAuthUsers/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 查询节点权限设置
 * @param params
 * @returns
 */
export function getNodeAuthPoint(
  params: GetNodeAuthPointParams,
  userConfig?: UserConfig,
): AxiosPromise<GetNodeAuthPointEntity> {
  return service(
    {
      url: `/zetareview/auth/getNodeAuthPoint/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 设置节点权限
 * @param params
 * @returns
 */
export function setAuthPoint(
  params: SetAuthPointParams,
  userConfig?: UserConfig,
): AxiosPromise<SetAuthPointEntity> {
  return service(
    {
      url: `/zetareview/auth/setAuthPoint`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 删除协作者
 * @param params
 * @returns
 */
export function removeUserAuth(
  params: AuthRemoveParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthRemoveEntity> {
  return service(
    {
      url: `/zetareview/auth/removeUserAuth/${params.userId}/${params.authGroupId}`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 编辑用户权限
 * @param params
 * @returns
 */
export function updateUserAuth(
  params: AuthUpdateParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthUpdateEntity> {
  return service(
    {
      url: `/zetareview/auth/updateUserAuth`,
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function createAuthority(
  params: AuthorityCreateParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthorityCreateEntity> {
  return service(
    {
      url: '/zetareview/auth/create',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function removeAuthority(
  params: AuthorityRemoveParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthorityRemoveEntity> {
  return service(
    {
      url: `/zetareview/auth`,
      method: 'DELETE',
      params,
    },
    userConfig,
  );
}

export function updateAuthority(
  params: AuthorityUpdateParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthorityUpdateEntity> {
  return service(
    {
      url: '/zetareview/auth/update',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function getAuthority(
  params: AuthorityGetParams,
  userConfig?: UserConfig,
): AxiosPromise<AuthorityGetEntity> {
  return service(
    {
      url: `/zetareview/auth/${params.userId}/${params.nodeId}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function updateProject(
  params: UpdateProjectParams,
  userConfig?: UserConfig,
): AxiosPromise<UpdateProjectEntity> {
  return service(
    {
      url: '/zetareview/project/update',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function addProject(
  params: AddProjectParams,
  userConfig?: UserConfig,
): AxiosPromise<AddProjectEntity> {
  return service(
    {
      url: '/zetareview/project/create',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function queryById(
  params: QueryNodeByIdParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryNodeByIdEntity> {
  return service(
    {
      url: `/zetareview/project/queryById`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function queryProjects(
  params: QueryParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryProjectsEntity> {
  return service(
    {
      url: '/zetareview/project/query',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function deleteProject(
  params: DeleteProjectParams,
  userConfig?: UserConfig,
): AxiosPromise<DeleteProjectEntity> {
  return service(
    {
      url: `/zetareview/project/delete/${params.id}`,
      method: 'DELETE',
    },
    userConfig,
  );
}

export function batchDeleteProject(
  params: BatchDeleteProjectParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/zetareview/project/batchDelete`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function fileProcess(
  params: FileProcessParams,
  userConfig?: UserConfig,
): AxiosPromise<FileProcessEntity> {
  return service(
    {
      url: '/zetareview/file/fileProcess',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function addFile(
  params: AddFileParams,
  userConfig?: UserConfig,
): AxiosPromise<AddFileEntity> {
  return service(
    {
      url: '/zetareview/file/create',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function updateFile(
  params: UpdateFileParams,
  userConfig?: UserConfig,
): AxiosPromise<UpdateFileEntity> {
  return service(
    {
      url: '/zetareview/file/rename',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

/**
 * 更新单个文件
 */
export function updateSingleFile(
  params: UpdateSingleFileParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: '/zetareview/file/updateSingleFile',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

// deprecated
export function queryFiles(
  params: QueryFilesParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryFilesEntity> {
  return service(
    {
      url: `/zetareview/file/query/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function batchDelete(
  params: BatchDeleteParams,
  userConfig?: UserConfig,
): AxiosPromise<BatchDeleteEntity> {
  return service(
    {
      url: `/zetareview/file/batchDelete`,
      method: 'DELETE',
      data: params,
    },
    userConfig,
  );
}

export function deleteFile(
  params: DeleteFileParams,
  userConfig?: UserConfig,
): AxiosPromise<DeleteFileEntity> {
  return service(
    {
      url: `/zetareview/file/delete/${params.id}`,
      method: 'DELETE',
    },
    userConfig,
  );
}

export function batchMove(
  params: BatchMoveParams,
  userConfig?: UserConfig,
): AxiosPromise<BatchMoveEntity> {
  return service(
    {
      url: '/zetareview/file/batchMove',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function downloadFile(
  params: DownloadFileParams,
  userConfig?: UserConfig,
): AxiosPromise<DownloadFileEntity> {
  return service(
    {
      url: `/zetareview/file/queryUrl/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function createFolder(
  params: CreateFolderParams,
  userConfig?: UserConfig,
): AxiosPromise<CreateFolderEntity> {
  return service(
    {
      url: '/zetareview/folder/create',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function updateFolder(
  params: UpdateFolderParams,
  userConfig?: UserConfig,
): AxiosPromise<UpdateFolderEntity> {
  return service(
    {
      url: '/zetareview/folder/rename',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function uploadFolderFile(
  params: UploadFolderFileParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: '/zetareview/file/uploadFolderFile',
      method: 'POST',
      data: params,
      // 由于这个接口涉及到大量数据创建
      // 超时时间设置为网关超时时间
      timeout: 5 * 60 * 1000,
    },
    userConfig,
  );
}

export function deleteFolder(
  params: DeleteFolderParams,
  userConfig?: UserConfig,
): AxiosPromise<DeleteFolderEntity> {
  return service(
    {
      url: `/zetareview/folder/delete/${params.id}`,
      method: 'DELETE',
    },
    userConfig,
  );
}

export function getBatchDownloadUrl(
  params: GetBatchDownloadUrlParams,
  userConfig?: UserConfig,
): AxiosPromise<GetBatchDownloadUrlEntity> {
  return service(
    {
      url: `/zetareview/file/getBatchDownloadUrl?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
      method: 'GET',
      // 由于这个接口涉及到大量数据创建
      // 超时时间设置为网关超时时间
      timeout: 5 * 60 * 1000,
    },
    userConfig,
  );
}

/**
 * 弃用, 在getBatchDownloadUrl直接获得obs链接
 * @param key
 * @returns
 */
export function batchDownloadFile(key: string): string {
  return `${
    globals.baseURL as string
  }/zetareview/file/batchDownloadFile?key=${encodeURIComponent(key)}`;
}

export function queryFolderById(
  params: QueryFolderParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryFolderEntity> {
  return service(
    {
      url: `/zetareview/project/queryFolderById/${params.id}/${params.placeOnFileId}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function queryObsUpUrl(
  params: QueryObsUpUrlParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryObsUpUrlEntity> {
  return service(
    {
      url: '/zetareview/file/queryObsUpUrl',
      params,
      method: 'GET',
    },
    userConfig,
  );
}

export function getDetailById(
  params: {
    id: string;
  },
  userConfig?: UserConfig,
): AxiosPromise<ProjectItem> {
  return service(
    {
      url: `/zetareview/project/getDetailById/${params.id}`,
      method: 'GET',
      params: {
        ignoreDelete: true,
      },
    },
    userConfig,
  );
}

/**
 * 获取具有ownerInfo的项目详情
 * @param params
 * @param userConfig
 * @returns
 */
export function getDetailByIdContainOwnerInfo(
  params: {
    id: string;
  },
  userConfig?: UserConfig,
): AxiosPromise<ProjectItem> {
  return service(
    {
      url: `/zetareview/project/getDetailById/${params.id}`,
      method: 'GET',
      params: {
        containOwnerInfo: true,
        ignoreDelete: true,
      },
    },
    userConfig,
  );
}

/**
 * 获取最新的存档列表
 * @param params
 * @returns
 */
export function getLatestPlaceOnFiles(
  params: LatestPlaceOnFilesParams,
  userConfig?: UserConfig,
): AxiosPromise<PlaceOnFile> {
  return service(
    {
      url: `/zetareview/place/getLatestPlaceOnFiles/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 获取存档的更改统计
 * @param params
 * @returns
 */
export function changeTypeStatistics(
  params: ChangeTypeStatisticsParams,
  userConfig?: UserConfig,
): AxiosPromise<ChangeTypeStatisticsEntity> {
  return service(
    {
      url: '/zetareview/project/changeTypeStatistics',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

// 版本管理

/**
 * 新增存档
 * @param params
 * @returns
 */
export function createPlaceOnFile(
  params: CreatePlaceOnFileParams,
  userConfig?: UserConfig,
): AxiosPromise<CreatePlaceOnFileEntity> {
  return service(
    {
      url: '/zetareview/place/createPlaceOnFile',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

export function getPlaceOnFiles(
  params: GetPlaceOnFilesParams,
  userConfig?: UserConfig,
): AxiosPromise<GetPlaceOnFilesEntity> {
  return service(
    {
      url: `/zetareview/place/getPlaceOnFiles/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 更新存档
 * @param params
 * @returns
 */
export function updatePlaceOnFile(
  params: UpdatePlaceOnFile,
  userConfig?: UserConfig,
): AxiosPromise<UpdatePlaceOnFileEntity> {
  return service(
    {
      url: '/zetareview/place/updatePlaceOnFile',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

// transfer接口迁移至此
export function transferProgress(
  id: string,
  userConfig?: UserConfig,
): AxiosPromise<TransferProgressEntity> {
  return service(
    {
      url: `/convert/model/jobDetail/${id}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function queryScsUrl(
  id: string | number,
  tag: string,
  userConfig?: UserConfig,
): AxiosPromise<{
  key: string;
  url: string;
}> {
  return service(
    {
      url: `/zetareview/file/queryScsUrl/${id}`,
      method: 'GET',
      params: {
        tag,
      },
    },
    userConfig,
  );
}

/**
 * 链接鉴权
 * @param params
 * @param userConfig
 * @returns
 */
export function accessByLink(
  params: {
    id: string;
  },
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/zetareview/share/accessByLink/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function getShareAuthGroup(
  params: GetShareAuthGroupParams,
  userConfig?: UserConfig,
): AxiosPromise<GetShareAuthGroupEntity> {
  return service(
    {
      url: `/zetareview/share/getShareAuthGroup/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

export function setShareAuth(
  params: SetShareAuthParams,
  userConfig?: UserConfig,
): AxiosPromise<SetShareAuthEntity> {
  return service(
    {
      url: `/zetareview/share/setShareAuth`,
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function setShareAuthGroup(
  params: SetShareAuthGroupParams,
  userConfig?: UserConfig,
): AxiosPromise<SetShareAuthGroupEntity> {
  return service(
    {
      url: `/zetareview/share/setShareAuthGroup`,
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

/**
 * 获取文件历史版本
 * @param id 文件id
 * @returns
 */
export function queryFileHistory(
  id: string,
  userConfig?: UserConfig,
): AxiosPromise<ProjectItem[]> {
  return service(
    {
      url: `/zetareview/file/queryFileHistory/${id}`,
      method: 'GET',
    },
    userConfig,
  );
}

// 分享相关接口

/**
 * 获取链接分享详情
 *
 * @export
 * @param {GetShareLinkDetailParams} params
 * @return {*}  {AxiosPromise<GetShareLinkDetailEntity>}
 */
export function getShareLinkDetail(
  params: GetShareLinkDetailParams,
  userConfig?: UserConfig,
): AxiosPromise<GetShareLinkDetailEntity> {
  return service(
    {
      url: `/zetareview/share/getShareLinkDetail/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 验证分享项目链接权限
 * @param params
 * @returns
 */
export function getShareProjectAuth(
  params: GetShareProjectAuthParams,
  userConfig?: UserConfig,
): AxiosPromise<GetShareProjectAuthEntity> {
  return service(
    {
      url: `/zetareview/share/getShareProjectAuth/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 查询项目分享列表
 * @param params
 * @returns
 */
export function queryShareList(
  params: QueryShareListParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryShareListEntity> {
  return service(
    {
      url: `/zetareview/share/queryList/${params.id}`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 通过文件Id反查文件所在项目，飞书发通知时候使用
 * @param params
 * @returns
 */
export function detailAndRoot(
  params: DetailAndRootParams,
  userConfig?: UserConfig,
): AxiosPromise<DetailAndRootEntity> {
  return service(
    {
      url: `/zetareview/project/detailAndRoot/${params.id}`,
      method: 'GET',
    },
    userConfig,
  );
}

// 企业相关接口

/**
 * 创建企业
 * @param params
 * @returns
 */
export function createCompany(
  params: CreateCompanyParams,
  userConfig?: UserConfig,
): AxiosPromise<CreateCompanyEntity> {
  return service(
    {
      url: '/organization/company/create',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 修改企业
 * @param params
 * @returns
 */
export function updateCompany(
  params: UpdateCompanyParams,
  userConfig?: UserConfig,
): AxiosPromise<UpdateCompanyEntity> {
  return service(
    {
      url: '/organization/company/updateCompany',
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

/**
 * 获取用户企业列表
 * @param params
 * @returns
 */
export function getUserCompanyList(
  params: GetUserCompanyListParams,
  userConfig?: UserConfig,
): AxiosPromise<GetUserCompanyListEntity> {
  return service(
    {
      url: `/organization/company/getUserCompanyList/${params.appId}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 获取企业用户列表
 * @param params
 * @returns
 */
export function queryCompanyUsers(
  params: QueryCompanyUsersParams,
  userConfig?: UserConfig,
): AxiosPromise<QueryCompanyUsersEntity> {
  // 默认请求用户类型改为租户层
  const defaultParams = {
    ...params,
    infoType: params.infoType ?? UserInfoType.Tenant,
  };
  return service(
    {
      url: `/organization/user/queryUsers`,
      method: 'POST',
      data: defaultParams,
    },
    userConfig,
  );
}

/**
 * 对企业增加用户
 * @param params
 * @returns
 */
export function addCompanyUser(
  params: AddCompanyUserParams,
  userConfig?: UserConfig,
): AxiosPromise<AddCompanyUserEntity> {
  return service(
    {
      url: `/organization/user/addCompanyUser`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 对企业增加外部联系人
 * @param params
 * @returns
 */
export function addCompanyOutsideUser(
  params: AddCompanyOutsideUserParams,
  userConfig?: UserConfig,
): AxiosPromise<AddCompanyOutsideUserEntity> {
  return service(
    {
      url: `/organization/user/addCompanyOutsideUser`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 对企业删除用户
 * @param params
 * @returns
 */
export function deleteCompanyUser(
  params: DeleteCompanyUserParams,
  userConfig?: UserConfig,
): AxiosPromise<DeleteCompanyUserEntity> {
  return service(
    {
      url: `/organization/user/deleteUser/${params.nodeId}`,
      method: 'DELETE',
      data: params,
    },
    userConfig,
  );
}

export function updateCompanyUserInfo(
  params: UpdateCompanyUserInfoParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/user/userInfo/${params.nodeId}`,
      method: 'PUT',
      data: {
        ...params,
        nodeId: undefined,
      },
    },
    userConfig,
  );
}

export function batchDeleteCompanyUser(
  params: BatchDeleteUserParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/user/batchDeleteUser`,
      method: 'POST',
      data: qs.stringify(params, {
        arrayFormat: 'repeat',
      }),
    },
    userConfig,
  );
}

/**
 * 设置公司管理员
 * @param params
 * @param userConfig
 * @returns
 */
export function setCompanyUserRole(
  params: SetCompanyUserRoleParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/user/setUserRole`,
      method: 'PUT',
      data: params,
    },
    userConfig,
  );
}

export function setCompanySuperAdmin(
  params: Partial<SetCompanyUserRoleParams>,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/v2/corps/${params.companyId}/owner?newOwnerMid=${params.nodeId}`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 邀请成员
 * @param params
 * @param userConfig
 * @returns
 */
export function inviteUser(
  params: InviteUserParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/user/inviteUser/${params.nodeId}`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 切换公司
 * @param params
 * @param userConfig
 * @returns
 */
export function switchCompany(
  params: SwitchCompanyParams,
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: `/organization/company/switchCompany/${params.companyId}`,
      method: 'GET',
    },
    userConfig,
  );
}

/**
 * 给飞书联系人发送消息
 * @param params
 * @returns
 */
export function sendBot(
  params: SendBotParams,
  userConfig?: UserConfig,
): AxiosPromise<SendBotEntity> {
  return service(
    {
      url: `/organization/bot/sendBot`,
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 根据 metaId 查询文件地址
 */
export function getUrlByMetaId(
  metaId: string,
): AxiosPromise<DownloadFileEntity> {
  return service({
    url: `/zetareview/file/queryUrlByMetaId/${metaId}`,
    method: 'GET',
  });
}

/**
 * 新增单个文件
 */

export function createSingleFile(
  params: AddFileParams,
  userConfig?: UserConfig,
): AxiosPromise<AddFileEntity> {
  return service(
    {
      url: '/zetareview/file/createSingleFile',
      method: 'POST',
      data: params,
    },
    userConfig,
  );
}

/**
 * 刷新企业已邀请用户
 * @param userConfig
 * @returns
 */
export function refreshInviteStatus(
  userConfig?: UserConfig,
): AxiosPromise<void> {
  return service(
    {
      url: '/organization/user/refreshInviteStatus',
      method: 'GET',
    },
    userConfig,
  );
}

export function checkDirectorAuth(
  userConfig?: UserConfig,
): AxiosPromise<string[]> {
  return service(
    {
      url: '/zetareview/auth/checkDirectorAuth',
      method: 'GET',
    },
    userConfig,
  );
}

export function queryUrlByKey(
  params: { key: string },
  userConfig?: UserConfig,
): AxiosPromise<{ url: string }> {
  return service(
    {
      url: '/zetareview/file/queryUrlByKey',
      method: 'GET',
      params,
    },
    userConfig,
  );
}

export function queryUrlByKeyBatch(
  params: { key: string[] },
  userConfig?: UserConfig,
): AxiosPromise<Record<string, { url: string }>> {
  // // 兼容，下次上线删除
  // if (process.env.VUE_APP_BUILD_ENV === 'prod') {
  //   return service(
  //     {
  //       url: `/zetareview/file/queryUrlByKeyBatch?${qs.stringify(params, {
  //         arrayFormat: 'repeat',
  //       })}`,
  //       method: 'GET',
  //     },
  //     userConfig,
  //   );
  // }
  return service(
    {
      url: `/zetareview/file/queryUrlByKeyBatch`,
      method: 'POST',
      data: params.key,
    },
    userConfig,
  );
}

/**
 * 创建示例工程
 * @returns
 */
export function createDemo(userConfig?: UserConfig): AxiosPromise<void> {
  return service(
    {
      url: `/zetareview/file/createDemo`,
      method: 'POST',
    },
    userConfig,
  );
}

/**
 * 批量修改version别名
 *
 * @export
 * @param {{
 *   idList?: string[];
 *   parentId?: string;
 *   placeOnFileId: string;
 *   versionTag: string;
 * }} body
 * @return {*}  {AxiosPromise<void>}
 */
export function batchSetVersionTag(body: {
  idList?: string[];
  parentId?: string;
  placeOnFileId: string;
  versionTag: string;
}): AxiosPromise<void> {
  return service({
    url: `/zetareview/file/batchSetVersionTag`,
    method: 'POST',
    data: body,
  });
}
