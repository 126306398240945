import router from '@/router';
import { Local } from '@/utils/storage';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { Toast } from 'vant';
import { ErrorEntity } from './modules/zpm/interface';

export interface UserConfig {
  banMessage?: boolean;
}

export function showMessage(
  userConfig: UserConfig,
  message: string,
  type: 'warning' | 'error' | 'success' = 'error',
) {
  if (userConfig.banMessage) {
    return;
  }
  let VantType: string = type;
  if (type === 'warning' || type === 'error') {
    VantType = 'fail';
  }
  Toast[VantType](message);
}

export function goToLogin() {
  Local.clear();
  router.replace('/login');
}

export function create401Error(response: AxiosResponse) {
  return Promise.reject(
    new AxiosError(
      `Request failed with status code ${401}`,
      [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][
        Math.floor(401 / 100) - 4
      ],
      response.config,
      response.request,
      response,
    ),
  );
}

export function createServiceError(res: ErrorEntity, response: AxiosResponse) {
  return Promise.reject(
    new AxiosError(
      `错误${res.error.code}: ${res.error.message || '请稍后再试'}`,
      [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][
        Math.floor(response.status / 100) - 4
      ],
      response.config,
      response.request,
      response,
    ),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorHandler(error: any, userConfig: UserConfig) {
  if (error && error.status === 401) {
    showMessage(userConfig, '未登录或登录失效，请重新登录', 'warning');
    goToLogin();
    return Promise.reject(error);
  }
  if (error && error.response) {
    const { status, data } = error.response;
    if (status === 401) {
      showMessage(userConfig, '未登录或登录失效，请重新登录', 'warning');
      goToLogin();
      return Promise.reject(error);
    } else if (status === 403) {
      showMessage(userConfig, '您没有权限执行此操作');
    } else if (status === 500) {
      showMessage(userConfig, '服务器出错了，请稍后再试');
    } else if (data && data.error && data.error.message) {
      showMessage(userConfig, data.error.message);
    } else {
      showMessage(userConfig, error.message || '请求失败，请稍后再试!');
    }
  } else if (error && error.message) {
    showMessage(userConfig, error.message);
  } else {
    showMessage(userConfig, '请求失败，请稍后再试!');
  }
  return Promise.reject(error);
}

export function responseHandler(res: ErrorEntity, userConfig) {
  if (res.error.message) {
    showMessage(userConfig, res.error.message);
  } else {
    showMessage(userConfig, '请求失败，请稍后再试!');
  }
}

export function serviceCreator(
  axiosConfig: AxiosRequestConfig,
  instanceConfigFunction: (
    instance: AxiosInstance,
    uConfig: UserConfig,
  ) => void,
) {
  const service = (
    config: AxiosRequestConfig,
    userConfig: UserConfig = {},
  ): AxiosPromise => {
    const inner = axios.create(axiosConfig);
    instanceConfigFunction(inner, userConfig);
    return inner(config);
  };

  return service;
}
