import user from './user';
import fileList from './fileList';
import router from './router';

const modules = {
  user,
  fileList,
  router,
};

export default modules;
