/* eslint-disable no-param-reassign */
import { Module } from 'vuex';
import { FileListState, RootStateTypes } from '../interface';
import { projectItemCreator } from './helpers';
import * as api from '@/service/modules/zpm';
import { QueryNodeByIdParams } from '@/service/modules/zpm/interface';

const fileList: Module<FileListState, RootStateTypes> = {
  namespaced: true,
  state: {
    selectedItem: projectItemCreator(),
  },
  getters: {},
  mutations: {
    GET_SELECTED_ITEM(state, payload) {
      state.selectedItem = payload;
    },
  },
  actions: {
    async getSelectedInfo({ commit }, params: QueryNodeByIdParams) {
      try {
        const { data } = await api.getDetailById({
          id: params.id,
        });
        commit('GET_SELECTED_ITEM', data || projectItemCreator());
      } catch (e) {
        // ignore
      }
    },
  },
};

export default fileList;
