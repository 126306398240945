/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
  // 设置永久缓存
  set(key: string, val: any = null) {
    const obj = val;
    if (key === 'userInfo') {
      if (!val.avatarThumb) obj.avatarThumb = val.avatar;
    }
    window.localStorage.setItem(key, JSON.stringify(obj));
  },
  // 获取永久缓存
  get(key: string) {
    const json: any = window.localStorage.getItem(key);
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  },
  // 移除永久缓存
  remove(key: string) {
    window.localStorage.removeItem(key);
  },
  // 移除全部永久缓存
  clear() {
    window.localStorage.clear();
  },
};

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
  // 设置临时缓存
  set(key: string, val: any = null) {
    window.sessionStorage.setItem(key, JSON.stringify(val));
  },
  // 获取临时缓存
  get(key: string) {
    const json: any = window.sessionStorage.getItem(key);
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  },
  // 移除临时缓存
  remove(key: string) {
    window.sessionStorage.removeItem(key);
  },
  // 移除全部临时缓存
  clear() {
    window.localStorage.removeItem('userInfo');
    window.localStorage.removeItem('token');
    window.sessionStorage.clear();
  },
};
