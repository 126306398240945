/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { AuthCode } from '@/utils/constants';

export interface ErrorEntity {
  error: {
    service: {
      name: string;
      uuid: string;
    };
    code: number;
    message: string;
    args: Record<string, any>;
  };
}

export interface AuthAddSingleParams {
  groupName: string;
  userId: string;
}

export type BatchAuthAddParams = AuthAddSingleParams[];

export interface AuthAddParams {
  groupName?: string;
  id: string;
  userIds: string[];
  ignoreHighLevelAuth?: string;
}

export type AuthAddEntity = Array<{
  changeType: 'ADD' | 'CHANGE';
  userOpenId: string;
  userInfo?: {
    avatar: string;
    email: string;
    enName: string;
    name: string;
    openId: string;
    phone: string;
    feishuUnionId: string;
    feishuOpenId?: Record<string, string>;
  };
}>;

export interface AuthorityParams {
  authGroupId: string;
  authPoint?: string;
}

export interface AuthGetParams {
  id: number | string;
}

export interface AuthGetUser {
  authGroupId: string;
  groupName: string;
  nickname: string;
  uid: string;
  project: ProjectItem;
  userInfo?: CompanyUserEntity;
}

export type AuthGetEntity = AuthGetUser[];

export interface AuthRemoveParams {
  authGroupId: string | number;
  userId: string | number;
  companyId?: string;
}

export type AuthRemoveEntity = void;

export interface GetNodeAuthPointParams {
  id: number | string;
}

export interface AuthGroup {
  groupName: string;
  id: number;
  nodeId: string;
}

export interface GetNodeAuthPointEntity {
  authGroups: AuthGroup[];
  authSetReqVO: {
    collaboratorsAuthSetVO: {
      authGroupId: number;
      authPoint: number;
      groupName: string;
      isInside: number;
      oldAuthGroupId: number;
    };
    download: {
      authGroupId: number;
      authPoint: number;
      groupName: string;
      // isInside: number
      oldAuthGroupId: number;
    };
    id: number;
    remark: {
      authGroupId: number;
      authPoint: number;
      groupName: string;
      // isInside: number
      oldAuthGroupId: number;
    };
    shareAuthSetVO: {
      authGroupId: number;
      authPoint: number;
      groupName: string;
      isExternalShare: number;
      oldAuthGroupId: number;
    };
  };
}

export enum GroupNames {
  Manager = 'MAN',
  Viewer = 'VIE',
  Editor = 'EDI',
  Owner = 'OWN',
}

export interface SetAuthPointParams {
  collaboratorsAuthSetVO: {
    authGroupId: number;
    authPoint: number;
    groupName: string;
    isInside: number;
    oldAuthGroupId: number;
  };
  download: {
    authGroupId: number;
    authPoint: number;
    groupName: string;
    // isInside: number
    oldAuthGroupId: number;
  };
  id: number;
  remark: {
    authGroupId: number;
    authPoint: number;
    groupName: string;
    // isInside: number
    oldAuthGroupId: number;
  };
  shareAuthSetVO: {
    authGroupId: number;
    authPoint: number;
    groupName: string;
    isExternalShare: number;
    oldAuthGroupId: number;
  };
}

export type SetAuthPointEntity = void;

export interface AuthUpdateParams {
  groupName: string;
  id: string;
  userId: string;
  companyId: string;
}

export type AuthUpdateEntity = Record<string, string>;

export type AuthorityCreateParams = AuthorityParams;

export type AuthorityCreateEntity = void;

export type AuthorityRemoveParams = AuthorityParams;

export type AuthorityRemoveEntity = void;

export type AuthorityUpdateParams = AuthorityParams;

export type AuthorityUpdateEntity = void;

export interface AuthorityGetParams {
  userId: string;
  nodeId: string;
}

export interface AuthorityGetEntity {
  authoritys: any[];
}

export interface AddProjectParams {
  name: string;
  remark: string;
  companyId?: string;
}

export interface AddProjectEntity {
  id: string;
}

export interface UpdateProjectParams extends AddProjectParams {
  id: string;
  name: string;
  remark: string;
}

export type UpdateProjectEntity = void;

export interface OrderParams {
  key: string;
  order: string;
}

export enum ChangeType {
  add,
  update,
}

export enum PlaceStatus {
  dead = 'dead',
  active = 'active',
}

export enum ProjectTypes {
  folder = 'folder',
  file = 'file',
  project = 'project',
}

export interface ProjectItem {
  /**
   * 权限冲突提示，用于未来恢复权限继承
   */
  authFlag: number;
  /**
   * 公司id
   */
  companyId: string;
  /**
   * 通过getDetail才能获取到了，权限点
   */
  authPoint: AuthCode[];
  /**
   * 是否删除标识
   */
  dr: number;
  /**
   * 主键
   */
  id: string;
  /**
   * 原始 ID
   */
  metaId: string;
  /**
   * 是否分享
   */
  isShare: number;
  /**
   * 文件存储的key
   */
  key: string;
  /**
   * 文件名称
   */
  name: string;
  /**
   * 是否对外分享
   */
  isExternalShare: number;
  /**
   * 是否内部分享
   */
  isInside: number;
  /**
   * 下一个节点的id
   */
  nextId: number;
  /**
   * 节点id
   */
  nodeId: string;
  /**
   * 父节点id
   */
  parentId: string;
  /**
   * 根节点id
   */
  rootId: string;
  /**
   * 根节点id
   */
  path: string;
  /**
   * 父节点名称
   */
  parentName: string;
  /**
   * 前一个节点id
   */
  preId: number;
  /**
   * 备注
   */
  remark: string;
  /**
   * 分享类型
   */
  shareType: string;
  /**
   * 转码id
   */
  jobId: string;
  /**
   * 文件大小
   */
  size: number;
  /**
   * 转码状态
   */
  status: number;
  /**
   * 文件类型
   */
  type: ProjectTypes;
  /**
   * 更改时间
   */
  updateTime: number;
  /**
   * 更改人
   */
  updatedBy: string;
  /**
   * 子节点
   */
  children: ProjectItem[];
  /**
   * 存档id
   */
  placeOnFileId: string;
  /**
   * 存档名称
   */
  placeOnFileName?: string;
  /**
   * 变化类型，0：新增，1：修改
   */
  changeType?: 0 | 1;
  /**
   * 版本号
   */
  version: number;
  /**
   * 版本别称
   */
  versionTag: string | null;
  /**
   * 内部使用，用于remark对应
   */
  versionStr: string;
  /**
   * 所有者昵称
   */
  owner: string;
  /**
   * 所有者信息
   */
  ownerInfo?: {
    avatar: string;
    email: string;
    enName: string;
    name: string;
    openId: string;
    phone: string;
    feishuUnionId: string;
    feishuOpenId?: Record<string, string>;
  };

  /**
   * 进度百分比
   */
  progressPercentage?: number;
}

export interface QueryProjectsEntity {
  records: ProjectItem[];
  total: number;
}

export interface QueryParams {
  orders: OrderParams[];
  page: number;
  search: string;
  size: number;
}

export interface QueryNodeByIdParams extends QueryParams {
  id: string;
  placeOnFileId: string;
}

export interface QueryNodeByIdEntity {
  records: ProjectItem[];
  total: number;
}

export interface DeleteProjectParams {
  id: string;
}

export type DeleteProjectEntity = void;

export type BatchDeleteProjectParams = string[];

export interface AddFileParams {
  parentId: string;
  name: string;
  key: string;
  rootId: string;
  spaceSize: number;
  placeOnFileId: string;
  targetFormats?: string[];
  companyId: string;
}

interface UpdateSingleFileByMetaIdParams extends AddFileParams {
  metaId: string;
}

export interface AddFileEntity {
  id: string;
  metaId: string;
}

export interface UpdateFileParams {
  id: string;
  parentId?: string;
  dst: string;
  src: string;
}

export type UpdateFileEntity = void;

export interface QueryFilesParams {
  id: number;
}

export type QueryFilesEntity = ProjectItem[];

export interface DeleteFileParams {
  id: string;
}

export type DeleteFileEntity = void;

export type BatchDeleteParams = string[];

export type BatchDeleteEntity = void;

export interface BatchMoveParams {
  ids: string[];
  parentId: string;
}

export type BatchMoveEntity = void;

export interface DownloadFileParams {
  id: string;
}

export interface DownloadFileEntity {
  url: string;
}

// 创建文件夹
export interface CreateFolderParams {
  parentId: string;
  name: string;
  rootId: string;
  placeOnFileId: string;
  companyId: string;
}

export interface CreateFolderEntity {
  id: string;
}

export interface UploadFolderFileParams {
  companyId: string;
  parentId: string;
  placeOnFileId: string;
  targetFormats?: string[];
  uploadFileInfoList: Array<{
    key: string;
    name: string;
    relativePath: string;
    spaceSize: number;
    transform?: boolean;
  }>;
}

// 编辑文件夹
export interface UpdateFolderParams {
  id: string;
  parentId: string;
  dst: string;
  src: string;
}

export type UpdateFolderEntity = void;

interface UpdateSingleFileByIdParams {
  id: string;
  key: string;
  name: string;
  spaceSize: number;
  targetFormats: string[];
}

export type UpdateSingleFileParams =
  | UpdateSingleFileByIdParams
  | UpdateSingleFileByMetaIdParams;

export type FileProcessParams = string[];

export interface FileProcessEntity {
  status: number;
  progress: number;
  progressPercentage: number;
}

// 删除文件夹
export interface DeleteFolderParams {
  id: string;
}

export type DeleteFolderEntity = void;

export interface GetBatchDownloadUrlParams {
  ids: string[];
  placeOnFileId?: string;
  checkAuth?: boolean;
}

export interface GetBatchDownloadUrlEntity {
  url: string;
}

export interface QueryFolderParams {
  id: string;
  placeOnFileId: string;
}

export type QueryFolderEntity = ProjectItem[];

export interface QueryObsUpUrlParams {
  key: string;
  size: number | string;
}

export interface QueryObsUpUrlEntity {
  key: string;
  url: string;
}

export interface CreatePlaceOnFileParams {
  branch: string;
  id: string;
  companyId?: string;
  name: string;
  placeOnFileId: string;
  remark: string;
}

export type CreatePlaceOnFileEntity = void;

export interface GetPlaceOnFilesParams {
  id: string;
}

export interface PlaceOnFile {
  branch: string;
  branchRemark: string;
  name: string;
  placeOnFileId: string;
  remark: string;
  rootId: string;
  updateTime: number;
  updatedBy: string;
  status: PlaceStatus;
  updaterInfo?: {
    avatar: string;
    email: string;
    enName: string;
    name: string;
    openId: string;
    phone: string;
  };
}

export type GetPlaceOnFilesEntity = PlaceOnFile[];

export interface LatestPlaceOnFilesParams {
  id: string;
}

export interface ChangeTypeStatisticsParams {
  placeOnFileId: string;
  dr?: string;
  type?: string;
}

export type ChangeTypeStatisticsEntity = Array<{
  changeType: number;
  cnt: number;
}>;

export interface UpdatePlaceOnFile {
  placeOnFileId: string;
  name: string;
  remark: string;
}

export type UpdatePlaceOnFileEntity = void;

export interface SetShareAuthGroupParams {
  groupName: string;
  id: string;
  shareType: number;
}

export type SetShareAuthGroupEntity = void;

export interface SetShareAuthParams {
  flag: number;
  id: string | number;
}

export type SetShareAuthEntity = void;

export enum ShareTypes {
  // 企业内部
  inner,
  // 企业外部
  outside,
}
export interface GetShareAuthGroupEntity {
  groupName: string;
  id: number;
  nodeId: string;
  shareType: ShareTypes;
}

export interface GetShareAuthGroupParams {
  id: string | number;
}

// 企业相关接口
export interface CreateCompanyParams {
  appId?: string;
  company: string;
  openId?: string;
  source?: string;
}

export type CreateCompanyEntity = UserCompanyEntity;

export interface UpdateCompanyParams {
  appId?: string;
  company: string;
  companyId: string;
  openId?: string;
}

export type UpdateCompanyEntity = void;

export interface GetUserCompanyListParams {
  appId: string;
}

export interface UserCompanyEntity {
  company: string;
  companyId: string;
  id: string;
  nodeId: string;
  source: string;
  updateTime: number;
  updatedBy: string;
  active: boolean;
  userRole: CompanyUserRole;
}

export type GetUserCompanyListEntity = UserCompanyEntity[];

// 查询企业内用户
export enum CompanyUserType {
  // 内部
  inner = '0',
  // 外部联系人
  outer = '1',
}

/** 企业用户类型 */
export enum UserInfoType {
  /** 租户层用户信息 */
  Tenant = 2,
  /** 应用层用户信息 */
  App = 3,
}

export interface QueryCompanyUsersParams extends QueryParams {
  appId?: string;
  companyId: string;
  isInside?: string;
  nodeId?: string;
  source?: string;
  onlyUser?: boolean;
  infoType?: UserInfoType;
}

export interface UpdateCompanyUserInfoParams {
  email?: string;
  name?: string;
  phone?: string;
  nodeId?: string;
}

export enum CompanyUserRole {
  superAdmin = '0',
  admin = '1',
  member = '2',
}

export interface CompanyUserEntity {
  avatar: string;
  avatarBig: string;
  avatarMiddle: string;
  avatarThumb: string;
  company: string;
  companyId: string;
  email: string;
  enName: string;
  isInside: CompanyUserType;
  name: string;
  feishuOpenId?: Record<string, string>;
  feishuUnionId?: string;
  nodeId: string;
  openId: string;
  phone: string;
  role: CompanyUserRole;
  updateBy: string;
  updateTime: number;
  zixelUserId: string;
}

export interface QueryCompanyUsersEntity {
  records: CompanyUserEntity[];
  total: number;
}

export interface AddCompanyUserParams {
  appId: string;
  companyId: string;
  emailOrMobile: string;
  name: string;
  role: string;
  source?: string;
}

export type AddCompanyUserEntity = void;

export interface DeleteCompanyUserParams {
  appId: string;
  nodeId: string;
}

export type DeleteCompanyUserEntity = void;

export interface AddCompanyOutsideUserParams {
  appId: string;
  company: string;
  companyId: string;
  emailOrMobile: string;
  name: string;
  role: string;
}

export type AddCompanyOutsideUserEntity = void;

export interface SetCompanyUserRoleParams {
  appId: string;
  isOnlyManager: boolean;
  nodeId: string;
  role: string;
  companyId?: string;
}

export interface InviteUserParams {
  nodeId: string;
}

export interface BatchDeleteUserParams {
  nodeIdList: string[];
}

export interface SwitchCompanyParams {
  companyId: string;
}

export interface MetionEntity {
  id: string;
  id_type: string;
  key: string;
  name: string;
  tenant_key: string;
}

export enum PROCESS_STATE {
  PROCESS_PENDING,
  PROCESS_START,
  PROCESS_DOWNLOAD,
  PROCESS_DOWNLOAD_FAILED,
  PROCESS_PROCESSING,
  PROCESS_CANCELING,
  PROCESS_CANCELED,
  PROCESS_PROCESSED,
  PROCESS_PROCESS_FAILED,
  PROCESS_UPLOADING,
  PROCESS_UPLOADING_FAILED,
  PROCESS_NOTSUPPORT,
}

export const PROCESS_STATE_MAP = {
  [PROCESS_STATE.PROCESS_PENDING]: '排队中',
  [PROCESS_STATE.PROCESS_START]: '任务开始',
  [PROCESS_STATE.PROCESS_DOWNLOAD]: '转码文件下载中',
  [PROCESS_STATE.PROCESS_DOWNLOAD_FAILED]: '转码文件下载失败',
  [PROCESS_STATE.PROCESS_PROCESSING]: '转码中',
  [PROCESS_STATE.PROCESS_CANCELING]: '取消转码任务中',
  [PROCESS_STATE.PROCESS_CANCELED]: '转码已取消',
  [PROCESS_STATE.PROCESS_PROCESSED]: '转码完成',
  [PROCESS_STATE.PROCESS_PROCESS_FAILED]: '转码失败',
  [PROCESS_STATE.PROCESS_UPLOADING]: '已转码文件上传中',
  [PROCESS_STATE.PROCESS_UPLOADING_FAILED]: '已转码文件上传失败',
  [PROCESS_STATE.PROCESS_NOTSUPPORT]: '该文件不支持转码',
};

export const PROCESS_FAILED_STATES = [
  PROCESS_STATE.PROCESS_DOWNLOAD_FAILED,
  PROCESS_STATE.PROCESS_CANCELING,
  PROCESS_STATE.PROCESS_CANCELED,
  PROCESS_STATE.PROCESS_PROCESS_FAILED,
  PROCESS_STATE.PROCESS_UPLOADING_FAILED,
];

export const PROCESS_STOP_STATES = [
  ...PROCESS_FAILED_STATES,
  PROCESS_STATE.PROCESS_PROCESSED,
  PROCESS_STATE.PROCESS_NOTSUPPORT,
];

export interface TransferProgressEntity {
  jobId: string;
  status: PROCESS_STATE;
  total: number;
  processed: number;
  progress: string;
}

export interface SenderEntity {
  id: string;
  id_type: string;
  sender_type: string;
  tenant_key: string;
}
export interface SendBotEntity {
  code: string;
  data: {
    body: {
      content: string;
    };
    chat_id: string;
    create_time: string;
    deleted: string;
    metions: MetionEntity[];
    message_id: string;
    msg_type: string;
    parent_id: string;
    root_id: string;
    sender: SenderEntity;
    update_time: string;
    updated: string;
    upper_message_id: string;
  };
}
export type DetailAndRootEntity = ProjectItem & {
  rootInfo: ProjectItem;
};
export interface SendBotParams {
  appId: string;
  content: string;
  msg_type: string;
  receive_id: string;
  tenant_key: string;
  // union_id
  receive_id_type: string;
}

export interface DetailAndRootParams {
  id: string;
}

export interface GetShareLinkDetailParams {
  id: string;
}

export type GetShareLinkDetailEntity = ProjectItem;

export interface GetShareProjectAuthParams {
  id: string;
}

export type GetShareProjectAuthEntity = void;

export interface QueryShareListParams extends QueryParams {
  id: string;
  placeOnFileId: string;
}

export interface QueryShareListEntity {
  records: ProjectItem[];
  total: number;
}

export interface SupportTypeGroup {
  type: string;
  list: string[];
}
export interface SupportType {
  groupName: string;
  groupList: SupportTypeGroup[];
}

export interface LocalKeysParams {
  token: string;
  accessToken: string;
  openId: string;
  tenantId: string;
}
